import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setMusic} from "../store/mainReducer";

const SoundBtn = () => {

    const dispatch = useDispatch()
    const onMusic = useSelector(state => state.main.music)
    let img = (onMusic) ? 'off' : 'on'
    function music () {
        dispatch(setMusic(!onMusic))
    }
    return (
        <button onClick={music} className="container__volume">
            <img src={`images/volume-${img}.svg`} width="42" height="42" alt=""/>
        </button>
    );
};

export default SoundBtn;