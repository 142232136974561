import React, {useState} from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import SoundBtn from "../components/SoundBtn";
import ServerConnect from "../service";
import {setUser} from "../store/mainReducer";
import {useDispatch, useSelector} from "react-redux";

const Broadcast = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.main.user)
    const [val, setVal] = useState('')
    let isDisabled = (val === '') ? [true, 'button_disabled'] : [false, '']
    const [wrong,setWrong] = useState(false)

    const right_code = 'ирелия'
    let content = []

    function sendCode() {
        if (val.toLowerCase() === right_code) {
            ServerConnect.sendPut('/api/user/' + user.vk_id, {'state4': true})
                .then(data => dispatch(setUser(data.user)))
        } else {
            setWrong(true)
            setTimeout(() => {
                setWrong(false)
            }, 2000)
        }
    }

    if (user.state4) {
        content.push(<p className="input-wrapper__message input-wrapper__message_type_green">Верное кодовое слово!</p>)
    }

    if (wrong) {
        content.push(<p className="input-wrapper__message input-wrapper__message_type_red">Увы, неверное слово.<br/>
            Попробуй еще раз</p>)
    }

    return (
        <div className="container game-page video-page show-page">
            <div className="container__content">
                <Header desc={"Благотворительный шоу-матч"}/>
                <div className="video-page__video-block video-block show-page__video-block">
                    <div className="video-block__frame show-page__frame">
                        <a href="https://vk.com/video/@lolwildrift?list=0d0e02926b348a8a4a&z=video-187455013_456244166" target="_blank"><img width="100%" src='/images/broadcast_img.jpg' alt=""/></a>
                    </div>
                    <p className="video-block__text show-page__text show-page__text_order_1">Смотри шоу-матч Unicorns of
                        Love VS Team Queso и участвуй
                        в интерактиве.</p>
                    <p className="video-block__text show-page__text show-page__text_order_2">Разгадай ребус в эфире и
                        введи отгадку в поле, чтобы активировать пятое украшение</p>
                    <a href="https://vk.com/video/@lolwildrift?list=0d0e02926b348a8a4a&z=video-187455013_456244166" target="_blank" className="button button_color_blue video-block__button">
                        <span>смотреть</span>
                    </a>
                </div>
                <div className="input-wrapper">
                    <input
                        type="text"
                        className="video-page__input-block input-block"
                        placeholder="Введи кодовое слово"
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                    />
                    {content}
                </div>
                <div className="game-page__buttons">
                    <button
                        onClick={sendCode}
                        disabled={isDisabled[0]}
                        className={"button button_color_blue main-button video-page__main-button " + isDisabled[1]}>
                        <span>отправить</span>
                    </button>
                    <BackBtn/>
                </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default Broadcast;