import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer";
import SoundBtn from "../components/SoundBtn";
import Stages from "../components/Stages";
import ServerConnect from "../service";

const MainPage = () => {

    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)


    function get_prize() {
        if (!user.prize_id) {
            ServerConnect.sendGet('/api/game/' + user.vk_id)
                .then(data => {
                    if (data==0) {
                        dispatch(setActivePanel('out_prize'))
                    } else {
                        dispatch(setUser(data)),
                        dispatch(setActivePanel('prize'))
                    }
            })
        } else {
            dispatch(setActivePanel('prize'))
        }
    }


    let content = []
    if (user.winner) {
        content.push(
            <button onClick={get_prize} className="button button_color_yellow main-button main-page__prize-button">
                <span>забрать подарок</span>
            </button>
        )
    }


    return (
        <div className="container main-page">
            <Stages/>
            <div className="container__content main-page__content">
                <a href="#" className="logo container__logo link_disabled">
                    <img width="98" height="64" src="images/logo.svg" alt=""/>
                </a>
                <p className="caption main-page__caption caption_color_white">Новогоднее ущелье</p>
                <p className="main-page__description">Новый год приходит<br/>
                    и в Дикое ущелье! Заглядывай<br/>
                    в приложение каждый день<br/>
                    и активируй <span style={{color:'#6DDDF9', fontWeight:'900'}}>5 украшений</span>, чтобы получить новогодний сюрприз<nobr> в Wild Rift.</nobr><br/></p>
                <button onClick={()=>dispatch(setActivePanel('about_prizes'))} className="button button_color_blue main-button main-page__main-button">
                    <span>о призах</span>
                </button>
                {content}
            </div>
            <SoundBtn/>
        </div>
    );
};

export default MainPage;