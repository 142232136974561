import React from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import SoundBtn from "../components/SoundBtn";
import {useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";

const PostCardDone = () => {

    const url = useSelector(state=>state.main.url)
    const user = useSelector(state=>state.main.user)
    const friend = useSelector(state=>state.main.friend)

    function send_share(friend_vk_id, friend_first_name) {
        let message = `${friend_vk_id}${friend_first_name} Новым годом! 🎄 #НовогоднееУщельеWR

Поздравь своих друзей в стиле Wild Rift: vk.com/app8026935`
        bridge.send("VKWebAppShowWallPostBox", {
            "owner_id": user.vk_id,
            "message": message,
            "attachments": "https://vk.com/app8026935",
            "link_button": "open_url",
            "link_image": `https://wildrift.gamesmm.ru/media/postcards/postcard_${user.vk_id}.png`,
        });
    }

    function share() {
        let friendId;
        let friend_vk_id;
        let friend_first_name;
        try {
            friendId = new URL(friend).pathname.slice(1,)
        } catch (e) {
            friendId = friend
        } finally {
            let res = bridge.send("VKWebAppCallAPIMethod",
                {
                    "method": "users.get",
                    "request_id": Date.now(),
                    "params": {
                        "user_ids": friendId,
                        "v":"5.131",
                        "access_token":"ddf9e8f48c3761026b892995d64fcf607503ae17e9d388c25e4e7b889021e223dbf0dbda5b2ae649b9f1f"}
                });
            res.catch(data=>{
                friend_first_name = 'C'
                friend_vk_id = ''
            })
                .then(data=>send_share(friend_vk_id, friend_first_name))
            res.then(data=> {
                friend_first_name = ` (${data.response[0].first_name}), c`
                friend_vk_id = `@id${data.response[0]['id']}`
            })
                .then(data=>send_share(friend_vk_id, friend_first_name))
        }
    }

    return (
        <div className="container game-page postacrd-page">
            <div className="container__content">
                <Header desc={"Новогодняя открытка"}/>
                <div className="postcard-page__postcard-block postcard-block">
                    <div className="postcard-block__pic">
                        <img width='327px' src={url+'?'+Date.now()} alt=''/>
                    </div>
                    <div className="postcard-block__text-content">
                        <p className="postcard-block__caption">Твоя новогодняя открытка <span>готова!</span></p>
                        <p className="postcard-block__description">Скачай ее или поделись <br/>
                            у себя на стене, чтобы <br/>
                            поздравить друга</p>
                    </div>
                </div>
                <div className="game-page__buttons game-page__buttons_wide">
                    <a href={url} target="_blank" className="button button_color_blue main-button">
                        <span>Получить открытку</span>
                    </a>
                    <button onClick={share} className="button button_color_blue main-button">
                        <span>Поделиться открыткой</span>
                    </button>
                </div>
                <BackBtn cl="game-page__back-button_centered"/>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default PostCardDone;