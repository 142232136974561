import React from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import SoundBtn from "../components/SoundBtn";

const BotGameDone = () => {
    return (
        <div className="container game-page bot-page-done">
            <div className="container__content">
                <Header desc={"Наряди ёлочку"}/>
                <p className="game-page__description">Праздничное настроение на высоте!</p>
                <div className="bot-game__decoration-block decoration-block">
                    <p className="decoration-block__caption">ёлочку уже нарядили —
                        <span>полюбуйся</span></p>
                    <img className="decoration-block__image" src="images/decorations-pic.png" width="120"
                         height="120" alt=""/>
                </div>
                <div className="game-page__buttons">
                    <a href="https://vk.com/wall-187455013_1081908"
                       target="_blank"
                       className="button button_color_blue main-button game-page__main-button">
                        <span>Посмотреть на ёлку</span>
                    </a>
                    <BackBtn cl="game-page__back-button"/>
                </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default BotGameDone;