import React, {useEffect, useState} from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import SoundBtn from "../components/SoundBtn";
import {useSelector} from "react-redux";
import ServerConnect from "../service";

const AvatarDone = () => {
    const user = useSelector(state=>state.main.user)
    const frame_id = useSelector(state=>state.main.frame_id)

    return (
        <div className="container game-page ava-page">
            <div className="container__content">
                <Header desc={"Праздничный костюм"}/>
                <div className="ava-page__prize">
                    <div className={`ava-wrapper ava-wrapper_frame_${frame_id}`}>
                        <img src={user.photo_200} width="238" height="188" alt=""/>
                    </div>
                    <p>Твоя новогодняя аватарка<br/>
                        <span>готова!</span></p>
                </div>
                <div className="game-page__buttons">
                    <a href={ServerConnect.front_url+'/media/avatars/photo_'+user.vk_id+'.png'} target="_blank" className="button button_color_blue main-button game-page__main-button">
                        <span>Получить аватарку</span>
                    </a>
                    <BackBtn cl="game-page__back-button"/>
                </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default AvatarDone;