import React, {useEffect, useState} from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setFriend, setUrl, setUser} from "../store/mainReducer";
import SoundBtn from "../components/SoundBtn";
import SelectHeroes from "../components/SelectHeroes";
import SelectWishes from "../components/SelectWishes";
import ServerConnect from "../service";

const PostCard = () => {

    const dispatch = useDispatch()
    const hero = useSelector(state=>state.main.hero)
    const user = useSelector(state=>state.main.user)
    const wish = useSelector(state=>state.main.wish)
    const friend = useSelector(state=>state.main.friend)
    let isDisabled = (friend !== '' && hero.name !== 'Чемпион' && wish.text !== 'Пожелание') ? [false, ''] : [true, 'button_disabled']


    useEffect(()=>{
        dispatch(setUrl(''))
        dispatch(setFriend(''))
    },[])

    function get_postcard () {
        if (!user.state3) {
        ServerConnect.sendPut('/api/user/'+user.vk_id, {'state3':true})
            .then(data=>dispatch(setUser(data.user)))
        }
        let friendId;
        try {
            friendId = new URL(friend).pathname.slice(1,)
        } catch (e) {
            friendId = friend
        } finally {
            let params = {hero: hero.img, wish_id: wish.id, friend_id: friendId}
            ServerConnect.sendGet('/api/postcard/' + user.vk_id, params)
                .then(data => dispatch(setUrl(ServerConnect.front_url + '/media/postcards/postcard_' + user.vk_id + '.png')))
                .then(data => dispatch(setActivePanel('postcard_done')))
        }
    }

    return (
        <div className="container game-page postacrd-page">
            <div className="container__content">
                <Header desc={"Новогодняя открытка"}/>
                <p className="game-page__description">Поздравь друзей в стиле Wild Rift! <br/>
                    Собери персональную открытку и поделись ею <br/>
                    на своей стене, чтобы активировать второе украшение</p>
                <input
                    type="text"
                    className="postacrd-page__input-block input-block"
                    placeholder="Вставь ссылку на профиль получателя"
                    value={friend}
                    onChange={(e)=>dispatch(setFriend(e.target.value))}
                />
                    <div className="selects postacrd-page__selects">
                        <SelectWishes/>
                        <SelectHeroes/>
                    </div>

                    <div className="game-page__buttons">
                        <button onClick={get_postcard}
                                disabled={isDisabled[0]}
                                className={"button button_color_blue main-button game-page__main-button " + isDisabled[1]}>
                            <span>Собрать открытку</span>
                        </button>
                        <BackBtn cl="game-page__back-button"/>
                    </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default PostCard;