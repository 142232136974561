import React, {useEffect, useState} from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import SoundBtn from "../components/SoundBtn";
import {useDispatch, useSelector} from "react-redux";
import ServerConnect from "../service";
import {setUser} from "../store/mainReducer";

const Prize = () => {

    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const [riotId, setRiotId] = useState('')
    let isDisabled = (riotId === '') ? [true, 'button_disabled'] : [false, '']
    const [prize, setPrize] = useState({img:'', text:''})

    const data = {
        1: {img:'/images/emotion.png', text:'сундук со случайной эмоцией!'},
        2: {img:'/images/return-effect.png', text:'сундук со случайным эффектом возвращения!'},
        3: {img:'/images/pose.png', text:'сундук со случайной позой образа!'},
        4: {img:'/images/pose.png', text:'сундук со случайной позой чемпиона!'},
        5: {img:'/images/form.png', text:'сундук со случайным образом!'},
        6: {img:'/images/effect.png', text:'сундук со случайным эффектом убийства!'}
    }


    useEffect(()=>{
        if (user.prize_id) {
            setPrize(data[user.prize_id])
        }
    },[])

    function sendId () {
        ServerConnect.sendPut('/api/user/' + user.vk_id, {'id_riot': riotId})
            .then(data => dispatch(setUser(data.user)))
    }

    let content = []

    if (user.id_riot) {
        content.push(<p style={{height:'50px'}} className="input-wrapper__message input-wrapper__message_type_green">RIOT ID ОТПРАВЛЕН!</p>)
    }

    return (
        <div className="container game-page prize-page">
            <div className="container__content">
                <Header desc={"Подарок в Wild Rift"}/>
                <div className="prize-block prize-page__prize-block">
                    <img className="prize-block__pic" src={prize.img} width="144" height="144" alt=""/>
                        <div className="prize-block__text-block">
                            <p>Поздравляем, твой подарок <span>{prize.text}</span></p>
                        </div>
                </div>
                <p className="prize-block__instruction">Обязательно
                    <a href="https://connect.ru.leagueoflegends.com/"
                       target="_blank"
                       style={{color:'#6DDDF9', fontWeight:"900"}}> свяжи свой VK с аккаунтом в Wild Rift</a>, а также
                    введи Riot ID с хештегом, чтобы получить приз (срок начисления — до 2 недель)
                </p>

                <div className='input-wrapper'>
                <input type="text"
                       className="prize-page__input-block input-block"
                       placeholder="Введи Riot ID с хэштегом, например Winner#123"
                       value={riotId}
                       onChange={(e)=>setRiotId(e.target.value)}
                />
                {content}
                </div>
                    <p className="prize-block__input-check">Проверь, правильно ли указаны данные</p>
                    <div className="game-page__buttons">
                        <button
                            onClick={sendId}
                            disabled={isDisabled[0]}
                            className={"button button_color_blue main-button prize-page__main-button" + isDisabled[1]}>
                            <span>отправить</span>
                        </button>
                        <BackBtn/>
                    </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default Prize;