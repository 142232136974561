import React from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";
import SoundBtn from "../components/SoundBtn";

const BotGame = () => {
    const dispatch = useDispatch()
    return (
        <div className="container game-page bot-page">
            <div className="container__content">
                <Header desc={"Наряди ёлочку"}/>
                <p className="game-page__description">Новый год без ёлки — не праздник! <br/>
                    Прими участие в игре и помоги своей команде нарядить праздничное дерево, чтобы побороться за образы
                    в Wild Rift и активировать третье украшение</p>
                <a href="https://vk.com/wall-187455013_1081908" target="_blank"
                    className="button button_color_blue bot-page__xl-button">
                    <span>Играть</span>
                </a>
                <div className="game-page__buttons game-page__buttons_tight bot-page__buttons">
                    <button onClick={()=>dispatch(setActivePanel('about_prizes'))} className="button button_color_white about-button game-page__about-button">
                        <span>О призах</span>
                    </button>
                    <BackBtn cl="game-page__back-button"/>
                </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default BotGame;