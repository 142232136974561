import React from 'react';
import Header from "../components/Header";
import BackBtn from "../components/BackBtn";
import SoundBtn from "../components/SoundBtn";

const AboutPrizes = () => {
    return (
        <div className="container game-page prizes-page">
            <div className="container__content">
                <Header desc={"Награды"}/>
                <div className="step-block prizes-page__first-step"><p>1</p><span>Активируй пять украшений на главном экране, чтобы <br/>
                открыть подарок и получить один из сундуков:</span></div>
                <div className="prizes-grid prizes-page__prizes-grid">
                    <div className="prizes-item">
                        <img src="images/emotion.png" width="55" height="55" alt=""/>
                            <p>со случайной<br/>
                                эмоцией</p>
                    </div>
                    <div className="prizes-item">
                        <img src="images/pose.png" width="55" height="55" alt=""/>
                            <p>со случайной<br/>
                                позой образа</p>
                    </div>
                    <div className="prizes-item">
                        <img src="images/form.png" width="55" height="55" alt=""/>
                            <p>со случайным<br/>
                                образом</p>
                    </div>
                    <div className="prizes-item">
                        <img src="images/return-effect.png" width="55" height="55" alt=""/>
                            <p>со случайным<br/>
                                эффектом Возвращения</p>
                    </div>
                    <div className="prizes-item">
                        <img src="images/champ-pose.png" width="55" height="55" alt=""/>
                            <p>со случайной<br/>
                                позой чемпиона</p>
                    </div>
                    <div className="prizes-item">
                        <img src="images/effect.png" width="55" height="55" alt=""/>
                            <p>со случайным<br/>
                                эффектом убийства</p>
                    </div>
                </div>
                <p className="prizes-page__hurry-text">Не мешкай — количество подарков ограничено!</p>
                <div className="step-block prizes-page__second-step"><p>2</p>
                    <span>Участвуй в мини-игре “Наряди елочку” <b>23 декабря</b> и попади в число
                        самых активных игроков, чтобы получить образ за 990 WC в Wild Rift!</span>
                </div>
                <BackBtn cl="prizes-page__back-button"/>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default AboutPrizes;