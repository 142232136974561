import React from 'react';
import {setActivePanel} from "../store/mainReducer";
import {useDispatch, useSelector} from "react-redux";

const Stages = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.main.user)
    const game = useSelector(state => state.main.game)
    const stages = {1: game.stage1, 2: game.stage2, 3: game.stage3, 4: game.stage4, 5: game.stage5}
    const states = {1: user.state1, 2: user.state2, 3: user.state3, 4: user.state4, 5: user.state5}
    let done_stage1 = (game.stage1_done) ? 'bot_game_done' :'bot_game'

    let content = []

    const data = {
        1: {panel: done_stage1, cl: 'action-lamps-top', w: '197', h: '350', img: 'lamps-top'},
        2: {panel: 'video', cl: 'action-lamps-bottom', w: '183', h: '250', img: 'lamps-bottom'},
        3: {panel: 'postcard', cl: 'action-sock', w: '139', h: '136', img: 'sock'},
        4: {panel: 'broadcast', cl: 'action-boxes', w: '147', h: '221', img: 'boxes'},
        5: {panel: 'avatar', cl: 'action-poro', w: '135', h: '150', img: 'poro'},
    }

    for (let i = 1; i < 6; i++) {
        if (stages[i]) {
            let done = (states[i]) ? 'on' : 'off'
            content.push(
                <button onClick={() => dispatch(setActivePanel(data[i].panel))} className={data[i].cl}>
                    <img width={data[i].w} height={data[i].h} src={`images/${data[i].img}-${done}.png`} alt=""/>
                </button>
            )
        }
    }

    return (
        <>
            <div className="main-page__actions actions">
                {content}
            </div>
        </>
    );
};

export default Stages;