import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setHero} from "../store/mainReducer";

const SelectHeroes = () => {

    const dispatch = useDispatch()
    const [isActive,setIsActive] = useState(true)
    const hero = useSelector(state=>state.main.hero)
    let sel = (isActive) ? 'select-block_active' : ''

    function selected () {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        selected()
    },[hero])

    const heroes = {
     1: {img: 'Akali', name: 'Акали'},
     2: {img: 'Akshan', name: 'Акшан'},
     3: {img: 'Alistar', name: 'Алистар'},
     4: {img: 'Amumu', name: 'Амуму'},
     5: {img: 'Ahri', name: 'Ари'},
     6: {img: 'Aurelion_Sol', name: 'Аурелион Сол'},
     7: {img: 'Blitzcrank', name: 'Блицкранк'},
     8: {img: 'Braum', name: 'Браум'},
     9: {img: 'Brand', name: 'Брэнд'},
     10: {img: 'Vi', name: 'Вай'},
     11: {img: 'Varus', name: 'Варус'},
     12: {img: 'Veigar', name: 'Вейгар'},
     13: {img: 'Vayne', name: 'Вейн'},
     14: {img: 'Wukong', name: 'Вуконг'},
     15: {img: 'Galio', name: 'Галио'},
     16: {img: 'Garen', name: 'Гарен'},
     17: {img: 'Gragas', name: 'Грагас'},
     18: {img: 'Graves', name: 'Грейвз'},
     19: {img: 'Darius', name: 'Дариус'},
     20: {img: 'Jax', name: 'Джакс'},
     21: {img: 'Jarvan_IV', name: 'Джарван IV'},
     22: {img: 'Jayce', name: 'Джейс'},
     23: {img: 'Jhin', name: 'Джин'},
     24: {img: 'Jinx', name: 'Джинкс'},
     25: {img: 'Diana', name: 'Диана'},
     26: {img: 'Draven', name: 'Дрейвен'},
     27: {img: 'Janna', name: 'Жанна'},
     28: {img: 'Zed', name: 'Зед'},
     29: {img: 'Ziggs', name: 'Зиггс'},
     30: {img: 'Irelia', name: 'Ирелия'},
     31: {img: 'Kha_Zix', name: "Ка'Зикс"},
     32: {img: 'Kai_sa', name: "Кай'Са"},
     33: {img: 'Camille', name: 'Камилла'},
     34: {img: 'Katarina', name: 'Катарина'},
     35: {img: 'Kayle', name: 'Кейл'},
     36: {img: 'Caitlyn', name: 'Кейтлин'},
     37: {img: 'Kennen', name: 'Кеннен'},
     38: {img: 'Corki', name: 'Корки'},
     39: {img: 'Xin_Zhao', name: 'Ксин Жао'},
     40: {img: 'Leona', name: 'Леона'},
     41: {img: 'Lee_Sin', name: 'Ли Син'},
     42: {img: 'Lulu', name: 'Лулу'},
     43: {img: 'Lux', name: 'Люкс'},
     44: {img: 'Lucian', name: 'Люциан'},
     45: {img: 'Malphite', name: 'Мальфит'},
     46: {img: 'Master_Yi', name: 'Мастер Йи'},
     47: {img: 'Miss_Fortune', name: 'Мисс Фортуна'},
     48: {img: 'Morgana', name: 'Моргана'},
     49: {img: 'Nami', name: 'Нами'},
     50: {img: 'Nasus', name: 'Насус'},
     51: {img: 'Nunu', name: 'Нуну и Виллумп'},
     52: {img: 'Olaf', name: 'Олаф'},
     53: {img: 'Orianna', name: 'Орианна'},
     54: {img: 'Pantheon', name: 'Пантеон'},
     55: {img: 'Rammus', name: 'Раммус'},
     56: {img: 'Rengar', name: 'Ренгар'},
     57: {img: 'Renekton', name: 'Ренектон'},
     58: {img: 'Riven', name: 'Ривен'},
     59: {img: 'Senna', name: 'Сенна'},
     60: {img: 'Seraphine', name: 'Серафина'},
     61: {img: 'Singed', name: 'Синджед'},
     62: {img: 'Sona', name: 'Сона'},
     63: {img: 'Soraka', name: 'Сорака'},
     64: {img: 'Twisted_Fate', name: 'Твистед Фэйт'},
     65: {img: 'Teemo', name: 'Тимо'},
     66: {img: 'Thresh', name: 'Треш'},
     67: {img: 'Tryndamere', name: 'Триндамир'},
     68: {img: 'Tristana', name: 'Тристана'},
     69: {img: 'Fizz', name: 'Физз'},
     70: {img: 'Fiora', name: 'Фиора'},
     71: {img: 'Shyvana', name: 'Шивана'},
     72: {img: 'Ezreal', name: 'Эзреаль'},
     73: {img: 'Annie', name: 'Энни'},
     74: {img: 'Ashe', name: 'Эш'},
     75: {img: 'Yasuo', name: 'Ясуо'}
    }

    
    let content = []

    for (let i = 1; i < 76; i++) {
        content.push(
            <div className="select-option">
                <input onChange={()=>{dispatch(setHero(heroes[i]))}}
                       id={`hero${i}`}
                       type="radio"
                       name="heroes"/>
                <label
                    htmlFor={`hero${i}`}>{heroes[i].name}</label>
            </div>
        )
    }


    return (
        <div onClick={selected} className={`select-block ${sel}`}>
            <p className="select-block__blank">
                <span>{hero.name}</span>
                Выбери, какой чемпион украсит открытку
            </p>
            <div className="select-block__options">
                {content}
            </div>
        </div>
    );
};

export default SelectHeroes;