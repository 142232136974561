import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        user: null,
        game: null,
        music: false,
        hero: {name:'Чемпион'},
        wish: {text:'Пожелание'},
        friend: '',
        frame_id: 1,
        url: ''
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setUser(state, action) {
            state.user = action.payload
        },
        setGame(state, action) {
            state.game = action.payload
        },
        setMusic(state, action) {
            state.music = action.payload
        },
        setHero(state, action) {
            state.hero = action.payload
        },
        setWish(state, action) {
            state.wish = action.payload
        },
        setFrame(state, action) {
            state.frame_id = action.payload
        },
        setUrl(state, action) {
            state.url = action.payload
        },
        setFriend(state, action) {
            state.friend = action.payload
        }

    }
})

export default mainSlice.reducer
export const {setActivePanel, setUser, setMusic, setWish, setHero, setGame, setFrame, setUrl, setFriend} = mainSlice.actions
