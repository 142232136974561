import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const BackBtn = (props) => {
    const dispatch = useDispatch()
    return (
        <button onClick={()=>dispatch(setActivePanel('main'))} className={"button button_color_white back-button " + props.cl}>
            <span>назад</span>
        </button>
    );
};

export default BackBtn;