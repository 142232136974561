import React, {useState} from 'react';
import BackBtn from "../components/BackBtn";
import Header from "../components/Header";
import {useDispatch, useSelector} from "react-redux";
import ServerConnect from "../service";
import {setUser} from "../store/mainReducer";
import SoundBtn from "../components/SoundBtn";

const Video = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const [val, setVal] = useState('')
    let isDisabled = (val === '') ? [true, 'button_disabled'] : [false, '']
    const [wrong,setWrong] = useState(false)

    const right_code = 'санта браум'
    const right_code2 = 'сантубраум'
    let content = []

    function sendCode() {
        if (val.toLowerCase() === right_code || val.toLowerCase()=== right_code2) {
            ServerConnect.sendPut('/api/user/' + user.vk_id, {'state2': true})
                .then(data => dispatch(setUser(data.user)))
        } else {
            setWrong(true)
            setTimeout(() => {
                setWrong(false)
            }, 2000)
        }
    }

    if (user.state2) {
        content.push(<p className="input-wrapper__message input-wrapper__message_type_green">Верное кодовое слово!</p>)
    }

    if (wrong) {
        content.push(<p className="input-wrapper__message input-wrapper__message_type_red">Увы, неверное слово.<br/>
            Попробуй еще раз</p>)
    }

    return (
        <div className="container game-page video-page">
            <div className="container__content">
                <Header desc={"Праздничное видео"}/>
                <div className="video-page__video-block video-block">
                    <div className="video-block__frame">
                        <iframe
                            src="https://vk.com/video_ext.php?oid=-187455013&id=456244165&hash=dd54bfc44576af81&hd=2"
                            width="100%"
                            height="100%"
                            allow="autoplay; fullscreen;
                            encrypted-media; picture-in-picture;"
                            frameBorder="0"></iframe>
                    </div>
                    <p className="video-block__text video-block__text_order_first">Вспомни самые важные события
                        в&nbsp;Wild Rift и&nbsp;прими участие в&nbsp;интерактиве!</p>
                    <p className="video-block__text video-block__text_order_second">Ищи буквы в видео, собери
                        кодовое слово и введи его в поле ниже (регистр не учитывается), чтобы активировать четвертое
                        украшение</p>
                </div>
                <div className='input-wrapper'>
                    <input
                        type="text"
                        className="video-page__input-block input-block"
                        placeholder="Введи кодовое слово"
                        value={val}
                        onChange={(e)=> setVal(e.target.value)}
                    />
                    {content}
                </div>
                    <div className="game-page__buttons">
                        <button
                            onClick={sendCode}
                            disabled={isDisabled[0]}
                            className={"button button_color_blue main-button video-page__main-button " + isDisabled[1]}>
                            <span>отправить</span>
                        </button>
                        <BackBtn/>
                    </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default Video;