import React from 'react';

const Header = ({desc}) => {
    return (<>
        <a href="#" className="logo container__logo link_disabled">
            <img width="98" height="64" src="images/logo.svg" alt=""/>
        </a>
        <p className="caption game-page__caption caption_color_white">Новогоднее ущелье</p>
        <p className="caption game-page__sub-caption caption_color_blue">{desc}</p>
    </>
    );
};

export default Header;