import React, {useEffect, useState} from 'react';
import {setActivePanel, setWish} from "../store/mainReducer";
import {useDispatch, useSelector} from "react-redux";

const SelectWishes = () => {

    const dispatch = useDispatch()
    const [isActive,setIsActive] = useState(true)
    const wish = useSelector(state=>state.main.wish)
    let sel = (isActive) ? 'select-block_active' : ''


    function selected () {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        selected()
    },[wish])

    const wishes = {
        1: {text: 'Здоровья'},
        2: {text: 'Благополучия'},
        3: {text: 'Успехов'},
        4: {text: 'Удачи'},
        5: {text: 'Вдохновения'},
        6: {text: 'Позитива'},
        7: {text: 'Всего и много'},
    }

    let content = []

    for (let i = 1; i < 8; i++) {
            content.push(
                <div className="select-option">
                    <input onChange={()=>dispatch(setWish({id: i, text: wishes[i].text}))}
                        id={`word${i}`}
                        type="radio"
                        name="words"/>
                    <label htmlFor={`word${i}`}>{wishes[i].text}</label>
                </div>
            )
        }


    return (
        <div onClick={selected} className={`select-block ${sel}`}>
            <p className="select-block__blank">
                <span>{wish.text}</span>
                Выбери, что хочешь пожелать
            </p>
            <div className="select-block__options">
                {content}
            </div>
        </div>
    );
};

export default SelectWishes;