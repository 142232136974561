import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setGame, setUser} from "./store/mainReducer";
import ServerConnect from "./service";
import Init from "./panels/Init";
import MainPage from "./panels/MainPage";
import AvatarScan from "./panels/AvatarScan";
import AvatarDone from "./panels/AvatarDone";
import BotGame from "./panels/BotGame";
import BotGameDone from "./panels/BotGameDone";
import AboutPrizes from "./panels/AboutPrizes";
import Video from "./panels/Video";
import Broadcast from "./panels/Broadcast";
import Prize from "./panels/Prize";
import OutPrize from "./panels/OutPrize";
import PostCard from "./panels/PostCard";
import PostCardDone from "./panels/PostCardDone";


const App = () => {

  const dispatch = useDispatch()
  const mus = document.querySelector("#bg_sound")
  const activePanel = useSelector(state => state.main.activePanel)
  const music = useSelector(state=>state.main.music)

  useEffect(()=>{
    mus.disableRemotePlayback = true;
    if (music) {
      mus.play()
    } else {
      mus.pause()
    }
  },[music])

  useEffect(() => {
    async function fetchData() {
      const user = await bridge.send('VKWebAppGetUserInfo');
      if (user) {
        return user;
      }
    }


    fetchData().then(data => get_user_info(data))

  }, [])

  async function get_user_info (user) {
    let response = await fetch(ServerConnect.api_url + '/api/user/' + user.id, );
    if (response.status === 204) {
      let context = {
        vk_id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        photo_200: user.photo_200,
        photo_max: user.photo_max_orig
      }
      ServerConnect.sendPost('/api/user/'+user.id, context)
          .then(data => {dispatch(setUser(data.user)), dispatch(setGame(data.game))})
    } else {
      let user_info = await response.json()
      dispatch(setUser(user_info.user))
      dispatch(setGame(user_info.game))
    }
  }


  let content = []
  if (activePanel === 'init') {
    content.push(<Init/>)
  } else if (activePanel === 'main') {
    content.push(<MainPage/>)
  } else if (activePanel === 'avatar') {
    content.push(<AvatarScan/>)
  } else if (activePanel === 'ava_done') {
    content.push(<AvatarDone/>)
  } else if (activePanel === 'bot_game') {
    content.push(<BotGame/>)
  } else if (activePanel === 'bot_game_done') {
    content.push(<BotGameDone/>)
  } else if (activePanel === 'about_prizes') {
    content.push(<AboutPrizes/>)
  } else if (activePanel === 'video') {
    content.push(<Video/>)
  } else if (activePanel === 'broadcast') {
    content.push(<Broadcast/>)
  } else if (activePanel === 'prize') {
    content.push(<Prize/>)
  } else if (activePanel === 'out_prize') {
    content.push(<OutPrize/>)
  } else if (activePanel === 'postcard') {
    content.push(<PostCard/>)
  } else if (activePanel === 'postcard_done') {
    content.push(<PostCardDone/>)
  }

  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;
