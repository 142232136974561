import React from 'react';
import Header from "../components/Header";
import BackBtn from "../components/BackBtn";
import SoundBtn from "../components/SoundBtn";

const OutPrize = () => {
    return (
        <div className="container game-page out-of-prize-page">
            <div className="container__content">
                <Header desc={"Подарок в Wild Rift"}/>
                <img className="out-of-prize-page__sad-pic" src="images/sad-pic.png" width="192" height="192"
                     alt=""/>
                    <p className="out-of-prize-page__text">
                        Ой, кажется, подарки<span> закончились…</span>
                    </p>
                    <BackBtn cl="out-of-prize-page__back-button"/>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default OutPrize;