import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setMusic} from "../store/mainReducer";

const Init = () => {

    const [progress, setProgress] = useState('progress-10');
    const [isActive, setIsActive] = useState(true)
    const dispatch = useDispatch()


    useEffect(()=>{
        setProgress('progress-100')
        setTimeout(()=> {
            setIsActive(false)
        },3000)
    },[progress])

    function start () {
        dispatch(setMusic(true))
        dispatch(setActivePanel('main'))
    }

    let content = [];

    if (isActive) {
        content.push(<>
            <div className="progress-bar__wrapper">
                <div className={`progress-bar__line ${progress}`}/>
            </div>
            <p className="progerss-bar__caption caption caption_color_white">загрузка</p>
        </>)
    } else {
        content.push(
            <button
                className="button button_color_blue main-button game-page__main-button"
                onClick={start}><span>Начать</span>
            </button>)
    }
    return (
        <div className="container start-screen">
            <div className="container__content">
                <a href="#" className="logo container__logo">
                    <img width="98" height="64" src="images/logo.svg" alt=""/>
                </a>
                <div className="start-screen__intro-block">
                    <p className="caption start-screen__caption caption_color_white">Новогоднее<br/>ущелье</p>
                    <div className="progerss-bar intro-block__progress-bar">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Init;