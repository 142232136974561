import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import BackBtn from "../components/BackBtn";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setFrame, setUser} from "../store/mainReducer";
import SoundBtn from "../components/SoundBtn";
import ServerConnect from "../service";

const AvatarScan = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const frame_id = useSelector(state=>state.main.frame_id)
    const [isActivate, setIsActivate] = useState(false)
    const onFingered = (isActivate) ? 'ava-page__hidden-pic' : 'ava-page__no-hidden-pic'

    useEffect(() => {
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }
        function getFrame() {
            const num = getRandomInt(1, 7)
            dispatch(setFrame(num))
        }
        getFrame()
    }, [])

    function finger() {
        setIsActivate(true)
        ServerConnect.sendGet('/api/avatar/'+user.vk_id, {frame_id:frame_id})

        if (!user.state5) {
            ServerConnect.sendPut('/api/user/'+user.vk_id, {'state5':true})
                .then(data=>dispatch(setUser(data.user)))
        }
        setTimeout(()=>{
            dispatch(setActivePanel('ava_done'))
        },3000)
    }

    return (
        <div className="container game-page ava-page">
            <div className="container__content">
                <Header desc={"Праздничный костюм"}/>
                <p className="game-page__description">
                    Какой праздник без наряда? <br/>
                    Поро подберут тебе подходящий новогодний аватар! <br/>
                    Когда он будет готов, обязательно нажми <span>“Получить аватар”</span>, <br/>чтобы активировать первое
                    украшение
                </p>
                <div className={onFingered}>
                    <img src="images/poro-pic.png" width="158" height="170" alt=""/>
                </div>
                    <div className="game-page__buttons">
                        <button
                            onClick={finger}
                            className="button button_color_blue main-button game-page__main-button">
                            <span>Пoросканировать</span>
                        </button>
                        <BackBtn cl="game-page__back-button"/>
                    </div>
            </div>
            <SoundBtn/>
        </div>
    );
};

export default AvatarScan;